#root, html, body{
    margin: 0;
    padding: 0;
    height: 100vh;
    box-sizing: border-box;
}

.app{
    height: 100%;
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)),  url('./images/wall.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.card{
    background-color: whitesmoke;
    width: 40%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2%;
    border: 1px solid wheat;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 20px;
    border: 1px solid wheat;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.heading{
    display: flex;
    align-items: center;
    height: 580px;
    font-family: "League Spartan", sans-serif;
}

.button {
    --btn-default-bg: rgb(41, 41, 41);
    --btn-padding: 15px 20px;
    --btn-hover-bg: rgb(51, 51, 51);
    --btn-transition: .3s;
    --btn-letter-spacing: .1rem;
    --btn-animation-duration: 1.2s;
    --btn-shadow-color: rgba(0, 0, 0, 0.137);
    --btn-shadow: 0 2px 10px 0 var(--btn-shadow-color);
    --hover-btn-color: #FAC921;
    --default-btn-color: #fff;
    --font-size: 16px;
    --font-weight: 600;
    --font-family: Menlo,Roboto Mono,monospace;
  }
  
  
  .button {
    box-sizing: border-box;
    padding: var(--btn-padding);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--default-btn-color);
    font: var(--font-weight) var(--font-size) var(--font-family);
    background: var(--btn-default-bg);
    border: none;
    cursor: pointer;
    transition: var(--btn-transition);
    overflow: hidden;
    box-shadow: var(--btn-shadow);
    border-radius: 25px;
    margin-bottom: 1rem;
    transition: 0.3s ease-in-out;
  }
  .button:hover {
    background: white;
  color: var(--glow-spread-color);
  transform: scale(1.03);
}

  .button span {
    letter-spacing: var(--btn-letter-spacing);
    transition: var(--btn-transition);
    box-sizing: border-box;
    position: relative;
    background: inherit;
  }
  
  .button span::before {
    box-sizing: border-box;
    position: absolute;
    content: "";
    background: inherit;
  }
  
  .button:hover, .button:focus {
    background: var(--btn-hover-bg);
  }
  
  .button:hover span, .button:focus span {
    color: var(--hover-btn-color);
  }
  
  .button:hover span::before, .button:focus span::before {
    animation: chitchat linear both var(--btn-animation-duration);
  }
  
  @keyframes chitchat {
    0% {
      content: "#";
    }
  
    5% {
      content: ".";
    }
  
    10% {
      content: "^{";
    }
  
    15% {
      content: "-!";
    }
  
    20% {
      content: "#$_";
    }
  
    25% {
      content: "№:0";
    }
  
    30% {
      content: "#{+.";
    }
  
    35% {
      content: "@}-?";
    }
  
    40% {
      content: "?{4@%";
    }
  
    45% {
      content: "=.,^!";
    }
  
    50% {
      content: "?2@%";
    }
  
    55% {
      content: "\;1}]";
    }
  
    60% {
      content: "?{%:%";
      right: 0;
    }
  
    65% {
      content: "|{f[4";
      right: 0;
    }
  
    70% {
      content: "{4%0%";
      right: 0;
    }
  
    75% {
      content: "'1_0<";
      right: 0;
    }
  
    80% {
      content: "{0%";
      right: 0;
    }
  
    85% {
      content: "]>'";
      right: 0;
    }
  
    90% {
      content: "4";
      right: 0;
    }
  
    95% {
      content: "2";
      right: 0;
    }
  
    100% {
      content: "";
      right: 0;
    }
  }

.footer{
    position: absolute;
    bottom: 10px;
    width: 100%;
    font-size: 14px;
    color: white;
    font-family: "League Spartan", sans-serif;
    background: transparent;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.footer a {
  color: #3c97bf ;
  text-decoration: none;
  font-weight: bolder;
}

@media only screen and (max-width: 600px) {
    .card {
        width: 80%;
        height: 30%;
    }
    .app{
      height: 100%;
    }
}